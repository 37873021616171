import React, { useEffect, useState } from "react";
import Intro from "../Intro";
import Services from "./ServicesList/Services";
import GetQuote from "../Contact/GetQuote";
import Reviews from "../Reviews";
import Gallery from "../Gallery/Gallery";
import EquipmentPricing from "./EquipmentPricing";
import basement1A from "../../Styles/Assets/Services/Basements/1A.jpg";
import basement1B from "../../Styles/Assets/Services/Basements/1B.jpg";
import basement2A from "../../Styles/Assets/Services/Basements/2A.jpg";
import basement2B from "../../Styles/Assets/Services/Basements/2B.jpg";
import basement3A from "../../Styles/Assets/Services/Basements/3A.jpg";
import basement3B from "../../Styles/Assets/Services/Basements/3B.jpg";
import basement4A from "../../Styles/Assets/Services/Basements/4A.jpg";
import basement4B from "../../Styles/Assets/Services/Basements/4B.jpg";
import basement5A from "../../Styles/Assets/Services/Basements/5A.jpg";
import basement5B from "../../Styles/Assets/Services/Basements/5B.jpg";
import basement6A from "../../Styles/Assets/Services/Basements/6A.jpg";
import basement6B from "../../Styles/Assets/Services/Basements/6B.jpg";
import basement7A from "../../Styles/Assets/Services/Basements/7A.jpg";
import basement7B from "../../Styles/Assets/Services/Basements/7B.jpg";
import basement8A from "../../Styles/Assets/Services/Basements/8A.jpg";
import basement8B from "../../Styles/Assets/Services/Basements/8B.jpg";
import basement9A from "../../Styles/Assets/Services/Basements/9A.jpg";
import basement10A from "../../Styles/Assets/Services/Basements/10A.jpg";
import basement10B from "../../Styles/Assets/Services/Basements/10B.jpg";

import finish1 from "../../Styles/Assets/Services/FinishCarpentry/1.jpg";
import finish2 from "../../Styles/Assets/Services/FinishCarpentry/2.jpg";
import finish3 from "../../Styles/Assets/Services/FinishCarpentry/3.jpg";

import flooring1 from "../../Styles/Assets/Services/Flooring/1.jpg";
import flooring2 from "../../Styles/Assets/Services/Flooring/2.jpg";
import flooring3 from "../../Styles/Assets/Services/Flooring/3.jpg";
import flooring4 from "../../Styles/Assets/Services/Flooring/4.jpg";
import flooring5 from "../../Styles/Assets/Services/Flooring/5.jpg";
import flooring6 from "../../Styles/Assets/Services/Flooring/6.jpg";
import flooring7 from "../../Styles/Assets/Services/Flooring/7.jpg";
import flooring8 from "../../Styles/Assets/Services/Flooring/8.jpg";
import flooring9 from "../../Styles/Assets/Services/Flooring/9.jpg";
import flooring10 from "../../Styles/Assets/Services/Flooring/10.jpg";
import flooring11 from "../../Styles/Assets/Services/Flooring/11.jpg";
import flooring12 from "../../Styles/Assets/Services/Flooring/12.jpg";
import flooring13 from "../../Styles/Assets/Services/Flooring/13.jpg";
import flooring14 from "../../Styles/Assets/Services/Flooring/14.jpg";
import flooring15 from "../../Styles/Assets/Services/Flooring/15.jpg";

import junk1A from "../../Styles/Assets/Services/JunkRemoval/1A.jpg";
import junk1B from "../../Styles/Assets/Services/JunkRemoval/1B.jpg";
import junk2A from "../../Styles/Assets/Services/JunkRemoval/2A.jpg";
import junk2B from "../../Styles/Assets/Services/JunkRemoval/2B.jpg";
import junk3A from "../../Styles/Assets/Services/JunkRemoval/3A.jpg";
import junk3B from "../../Styles/Assets/Services/JunkRemoval/3B.jpg";
import junk4A from "../../Styles/Assets/Services/JunkRemoval/4A.jpg";
import junk4B from "../../Styles/Assets/Services/JunkRemoval/4B.jpg";
import junk5A from "../../Styles/Assets/Services/JunkRemoval/5A.jpg";
import junk5B from "../../Styles/Assets/Services/JunkRemoval/5B.jpg";

import roofing1 from "../../Styles/Assets/Services/Roofing/1.jpg";
import roofing2 from "../../Styles/Assets/Services/Roofing/2.jpg";
import roofing4 from "../../Styles/Assets/Services/Roofing/4.jpg";
import roofing5 from "../../Styles/Assets/Services/Roofing/5.jpg";
import roofing6 from "../../Styles/Assets/Services/Roofing/6.jpg";

import remodel_AF555_after_downstairs_bathroom from "../../Styles/Assets/Services/Remodels/AF555/After/Downstairs_Bathroom.JPG";
import remodel_AF555_after_downstairs_bedroom_northeast from "../../Styles/Assets/Services/Remodels/AF555/After/Downstairs_Bedroom_Northeast.JPG";
import remodel_AF555_after_downstairs_bedroom_northwest from "../../Styles/Assets/Services/Remodels/AF555/After/Downstairs_Bedroom_Northwest.JPG";
import remodel_AF555_after_downstairs_entry from "../../Styles/Assets/Services/Remodels/AF555/After/Downstairs_Entry.JPG";
import remodel_AF555_after_downstairs_kitchen from "../../Styles/Assets/Services/Remodels/AF555/After/Downstairs_Kitchen.JPG";
import remodel_AF555_after_downstairs_laundry from "../../Styles/Assets/Services/Remodels/AF555/After/Downstairs_Laundry.JPG";
import remodel_AF555_after_downstairs_living from "../../Styles/Assets/Services/Remodels/AF555/After/Downstairs_Living.JPG";
import remodel_AF555_after_Outside_Back from "../../Styles/Assets/Services/Remodels/AF555/After/Outside_Back.JPG";
import remodel_AF555_after_Outside_Front from "../../Styles/Assets/Services/Remodels/AF555/After/Outside_Front.JPG";
import remodel_AF555_after_Upstairs_Bathroom from "../../Styles/Assets/Services/Remodels/AF555/After/Upstairs_Bathroom.JPG";
import remodel_AF555_after_Upstairs_Bedroom_Northeast from "../../Styles/Assets/Services/Remodels/AF555/After/Upstairs_Bedroom_Northeast.JPG";
import remodel_AF555_after_Upstairs_Bedroom_Northwest from "../../Styles/Assets/Services/Remodels/AF555/After/Upstairs_Bedroom_Northwest.JPG";
import remodel_AF555_after_Upstairs_Bedroom_Southwest from "../../Styles/Assets/Services/Remodels/AF555/After/Upstairs_Bedroom_Southwest.JPG";
import remodel_AF555_after_Upstairs_Dining from "../../Styles/Assets/Services/Remodels/AF555/After/Upstairs_Dining.JPG";
import remodel_AF555_after_Upstairs_Kitchen from "../../Styles/Assets/Services/Remodels/AF555/After/Upstairs_Kitchen.JPG";
import remodel_AF555_after_Upstairs_Laundry from "../../Styles/Assets/Services/Remodels/AF555/After/Upstairs_Laundry.JPG";
import remodel_AF555_after_Upstairs_Living from "../../Styles/Assets/Services/Remodels/AF555/After/Upstairs_Living.JPG";
import remodel_AF555_after_Upstairs_Pantry from "../../Styles/Assets/Services/Remodels/AF555/After/Upstairs_Pantry.JPG";

import image0 from "../../Styles/Assets/Services/Remodels/1322/image0.jpeg";

import image1 from "../../Styles/Assets/Services/Remodels/1322/image1.jpeg";

import image2 from "../../Styles/Assets/Services/Remodels/1322/image2.jpg";

import image3 from "../../Styles/Assets/Services/Remodels/1322/image3.jpeg";

import image4 from "../../Styles/Assets/Services/Remodels/1322/image4.jpeg";

import image5 from "../../Styles/Assets/Services/Remodels/1322/image5.jpeg";

import image6 from "../../Styles/Assets/Services/Remodels/1322/image6.jpeg";

import image7 from "../../Styles/Assets/Services/Remodels/1322/image7.jpeg";

import image8 from "../../Styles/Assets/Services/Remodels/1322/image8.jpeg";

import image9 from "../../Styles/Assets/Services/Remodels/1322/image9.jpeg";
import image10 from "../../Styles/Assets/Services/Remodels/1322/image10.jpeg";
import image11 from "../../Styles/Assets/Services/Remodels/1322/image11.jpeg";
import image12 from "../../Styles/Assets/Services/Remodels/1322/image12.jpeg";
import image13 from "../../Styles/Assets/Services/Remodels/1322/image13.jpeg";
import image14 from "../../Styles/Assets/Services/Remodels/1322/image14.jpeg";
import image15 from "../../Styles/Assets/Services/Remodels/1322/image15.jpeg";
import laundry1 from "../../Styles/Assets/Services/Remodels/Laundry/1.jpg";
import laundry2 from "../../Styles/Assets/Services/Remodels/Laundry/2.jpg";
import laundry3 from "../../Styles/Assets/Services/Remodels/Laundry/3.jpg";
import laundry3a from "../../Styles/Assets/Services/Remodels/Laundry/3a.jpg";
import laundry4 from "../../Styles/Assets/Services/Remodels/Laundry/4.jpg";
import laundry5 from "../../Styles/Assets/Services/Remodels/Laundry/5.jpg";
import laundry6 from "../../Styles/Assets/Services/Remodels/Laundry/6.jpg";

import tree1 from "../../Styles/Assets/Services/TreeRemoval/1.jpg";
import tree2 from "../../Styles/Assets/Services/TreeRemoval/2.jpg";
import tree3 from "../../Styles/Assets/Services/TreeRemoval/3.jpg";
import dumpster1 from "../../Styles/Assets/Services/Dumpsters/dumpster-temp.jpg";
import equipment from "../../Styles/Assets/Services/Equipment/equipment.jpg";
import DumpsterInfo from "./ServicesList/DumpsterInfo";
// import dumpster2 from "../../Styles/Assets/Services/TreeRemoval/2.jpg";
// import dumpster3 from "../../Styles/Assets/Services/TreeRemoval/3.jpg";

const Service = (props) => {
  // ===== ===== DATA BEG ===== =====
  const [state, setState] = useState({
    introTitle: "Carpentry",
    secondaryText: "",
    images: [],
    description: "",
  });
  // let images, introTitle, secondaryText;
  // let description = (
  //   <p className="service_summary-p">
  //     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
  //     tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
  //     veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
  //     commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
  //     velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
  //     cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
  //     est laborum.
  //   </p>
  // );
  useEffect(() => {
    console.log("props.location.pathname", props.location.pathname);
    serviceRouter();
  }, [props.location.pathname]);
  const serviceRouter = () => {
    let introTitle, images, secondaryText, description;

    switch (props.location.pathname) {
      case "/services/roofing":
        introTitle = "Roofing";
        description =
          "Our roofing experts have experience protecting Utah's homes by installing and repairing high-quality roofs. We understand that the roof is one of the most important features of any home — both structurally and aesthetically — which is why we use premium materials, never cut corners, and do every job the right way.";
        images = [[roofing4], [roofing6], [roofing5], [roofing1], [roofing2]];
        setState({
          introTitle,
          description,
          images,
        });

        break;

      case "/services/flooring":
        introTitle = "Flooring";
        description =
          "All of the floors we install are beautiful and long-lasting, so you'll be able to enjoy them for years to come. We also offer custom vinyl flooring. Like everything else we do, we stand by the quality of every flooring job we take on.";
        images = [
          [flooring2],
          [flooring5],
          [flooring4],
          [flooring6],
          [flooring3],
          [flooring1],
          [flooring7],
          [flooring8],
          [flooring9],
          [flooring10],
          [flooring11],
          [flooring12],
          [flooring13],
          [flooring14],
          [flooring15],
        ];
        setState({
          introTitle,
          description,
          images,
        });
        break;

      case "/services/remodels":
        introTitle = "Remodels";
        description =
          "If you're looking to remodel your house or business, we have you covered. We'll work with you to determine your goals, then we'll take it from there and bring them to life. With our help, your home or business will match your vision before you know it!";
        images = [
          [image0],
          [image1],
          [image2],
          [image3],
          [image4],
          [image5],
          [image6],
          [image7],
          [image8],
          [image9],
          [image10],
          [image11],
          [image12],
          [image13],
          [image14],
          [image15],
          [remodel_AF555_after_downstairs_bathroom],
          [remodel_AF555_after_downstairs_bedroom_northeast],
          [remodel_AF555_after_downstairs_bedroom_northwest],
          [remodel_AF555_after_downstairs_entry],
          [remodel_AF555_after_downstairs_kitchen],
          [remodel_AF555_after_downstairs_laundry],
          [remodel_AF555_after_downstairs_living],
          [remodel_AF555_after_Outside_Back],
          [remodel_AF555_after_Outside_Front],
          [remodel_AF555_after_Upstairs_Bathroom],
          [remodel_AF555_after_Upstairs_Bedroom_Northeast],
          [remodel_AF555_after_Upstairs_Bedroom_Northwest],
          [remodel_AF555_after_Upstairs_Bedroom_Southwest],
          [remodel_AF555_after_Upstairs_Dining],
          [remodel_AF555_after_Upstairs_Kitchen],
          [remodel_AF555_after_Upstairs_Laundry],
          [remodel_AF555_after_Upstairs_Living],
          [remodel_AF555_after_Upstairs_Pantry],
          [laundry1],
          [laundry2],
          [laundry3],
          [laundry4],
          [laundry3a],
          [laundry5],
          [laundry6],
        ];
        setState({
          introTitle,
          description,
          images,
        });
        break;

      case "/services/basements":
        introTitle = "Basements";
        description =
          "With our help, finally finishing your basement is a realistic goal. At Quality Carpentry, we're known for our beautiful and high-quality basements that increase the value of houses. Whether you're building a basement apartment or creating more space for your growing family, we can get the job done.";
        images = [
          [basement1A, basement1B],
          [basement2A, basement2B],
          [basement3A, basement3B],
          [basement4A, basement4B],
          [basement5A, basement5B],
          [basement6A, basement6B],
          [basement7A, basement7B],
          [basement8A, basement8B],
          [basement9A],
          [basement10A, basement10B],
        ];
        setState({
          introTitle,
          description,
          images,
        });
        break;

      case "/services/finish-carpentry":
        introTitle = "Finish Carpentry";
        description =
          "Our team has over 30 years of experience in contract work, flipping houses, finish carpentry, construction, and general woodworking. If you're working on a project that isn't listed here, please let us know! We'll be happy to help however we can.";
        images = [[finish1], [finish2], [finish3]];
        setState({
          introTitle,
          description,
          images,
        });
        break;

      case "/services/junk-removal":
        introTitle = "Junk Removal";
        description =
          "We can get rid of everything—inside and out—that's detracting from the value and beauty of your home. Be it trees, trash, debris, furniture or anything else you no longer want or need, we make it easy to get rid of your junk. Let's make your house feel like home again.";
        images = [
          [junk1B, junk1A],
          [junk2B, junk2A],
          [junk3B, junk3A],
          [junk4B, junk4A],
          [junk5B, junk5A],
        ];
        setState({
          introTitle,
          description,
          images,
        });
        break;

      case "/services/tree-removal":
        introTitle = "Tree Removal";
        description =
          "Trees are the perfect addition to any home—when they're in the right condition and the right spot. If you have a tree that's posing a threat to your house (or a tree that you don't like), we'd be happy to trim it or remove it. We'll be sure to do it quicky and in the right way so your home stays safe and beautiful.";
        images = [[tree1], [tree2], [tree3]];
        setState({
          introTitle,
          description,
          images,
        });
        break;
      case "/services/dumpster-rental":
        introTitle = "Dumpster Rental";
        description = [
          `Get your building projects cleared and finished quickly. `,
          `
     Our dumpsters are perfect for all types of building jobs and come in a range of sizes, including small 18 yard capacity, medium 25 yard capacity, and large 30 yard capacity. `,
          `  
      Say goodbye to slow manual cleanups, with one of our Texas Pride dumpsters you can make quick work of removing any unwanted construction materials in an efficient and organized manner. By getting the right size dumpster for your job, you can clear out any project in no time and make room for the next.`,
          `Check out Quality Carpentry's range of dumpster rentals and get your building projects done in no time. `,
        ];
        images = [[dumpster1]];

        setState({
          introTitle,
          description,
          images,
          extraComponent: <DumpsterInfo />,
        });
        break;
      case "/services/equipment-rental":
        introTitle = "Construction Equipment Rental in Spanish Fork, UT";
        description = [
          `
            Introducing Quality Rent and Lease, your go-to solution for construction equipment
            rentals in Spanish Fork, UT. Our business is centered around providing top-quality
            construction machinery to meet your project needs. Currently, our fleet boasts a CAT
            305 Mini Excavator, equipped with your choice of a 3' or 18' bucket, and a CAT 259D
            Track Skid Steer Loader. But that's not all! We're continually expanding our inventory to
            offer you even more options. `,
          `
            At Quality Rent and Lease, we understand the importance of convenience and
            affordability. That's why we offer a trailer rental option for just an additional $50,
            ensuring you can easily transport the equipment to your work site. If you prefer even
            more convenience, our local delivery service, available for only $50, will bring the
            machinery right to your doorstep. We also offer attractive discounts for extended rental
            periods, making it more cost-effective for your long-term projects. With daily rental rates
            of $300 for the CAT 305 Mini Excavator and $250 for the CAT 259D Track Skid Steer
            Loader, Quality Rent and Lease is your trusted partner in the construction equipment
            rental industry. `,
          `  
            Text or Call 385-244-7957, or email sales@qualityutah.com to schedule your rental
            today!`,
        ];
        images = [[equipment]];

        setState({
          introTitle,
          description,
          images,
          extraComponent: null,
        });
        break;
      default:
        images = null;
    }
  };

  // ===== ===== DATA END ===== =====

  // ===== ===== 'COMPONENTS' BEG ===== =====

  // ===== ===== 'COMPONENTS' END ===== =====

  return (
    <div>
      <Intro
        primaryText={"Quality " + state?.introTitle}
        secondaryText={state?.secondaryText}
      />
      <div className="service_summary">
        <h3 className="service_summary-h3">
          {" "}
          {state?.introTitle.toUpperCase()}{" "}
        </h3>
        {state?.introTitle?.toLowerCase()?.includes("equipment") && (
          <Gallery images={state?.images} />
        )}
        {Array.isArray(state?.description) ? (
          state.description.map((text, i) => {
            return (
              <p key={i} className="service_summary-p">
                {text}
              </p>
            );
          })
        ) : (
          <p className="service_summary-p">{state.description}</p>
        )}
        {state?.extraComponent && state.extraComponent}
        {!state?.introTitle?.toLowerCase()?.includes("equipment") && (
          <Gallery images={state?.images} />
        )}
        {state?.introTitle?.toLowerCase()?.includes("equipment") && (
          <EquipmentPricing />
        )}
      </div>
      <GetQuote title="GET A QUOTE" />
      <Reviews background="#e3e3e3" />
      <Services title="Our other services" />
    </div>
  );
};

export default Service;
