// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAmjrhqjyXkxEGyS7aWQzJb0TvIvgiVytA",
  authDomain: "quality-carpentry.firebaseapp.com",
  projectId: "quality-carpentry",
  storageBucket: "quality-carpentry.appspot.com",
  messagingSenderId: "353942302627",
  appId: "1:353942302627:web:967fdb0a4e1afa1861f416",
  measurementId: "G-QY1NMT1DJY"
};

console.log('Firebase')
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);