import React from "react";
import dumpsterPricing from "../../../Styles/Assets/Services/Dumpsters/dumpster_pricing.jpg";
function DumpsterInfo() {
  return (
    <div className="service-summary-p mt-3">
      <img src={dumpsterPricing} style={{marginTop: '20px', maxWidth: '800px'}}/>

      {/* <div style={{ width: "100%" }}>
        <ul style={{ width: "50%", margin: "auto" }}>
          <h4
            style={{ textAlign: "start", padding: "0", marginBottom: "10px" }}
          >
            Details:{" "}
          </h4>
          <li>- Rentals are on a weekly basis.</li>
          <li>- Additional charges per extra dump.</li>
          <li>- No filling above the line.</li>
          <li>- No concrete or rock.</li>
          <li>- 3 ton limit.</li>
        </ul>
      </div> */}
    </div>
  );
}

export default DumpsterInfo;
